import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"scroll-y pa-0",attrs:{"justify-start":""}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.cards),function(card,index){return _c(VListItem,{key:index,staticClass:"pa-0"},[_c(VListItemContent,[_c(VRow,{attrs:{"justify":"start","align":"start","no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":{ path: '/details/' + _vm.entry(card).id }}},[_c('ImageContainer',{attrs:{"entry":_vm.entry(card),"imagetype":_vm.imagetype}})],1)],1),_c(VCol,[_c(VCard,{staticClass:"pa-0 mx-1",attrs:{"tile":"","flat":""}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.entry(card).title))]),_c(VListItemSubtitle,_vm._l((_vm.entry(card).originalPublisher),function(orgpub,i){return _c('span',{key:i},[_c('router-link',{attrs:{"to":{ path: '/publisher/' + orgpub.name }}},[_vm._v(_vm._s(orgpub.name)+" "+_vm._s(orgpub.country))]),(i != Object.keys(_vm.entry(card).originalPublisher).length - 1)?_c('span',[_vm._v("/ ")]):_vm._e()],1)}),0),_c(VListItemSubtitle,{staticStyle:{"white-space":"normal"}},[_c('router-link',{attrs:{"to":{ path: '/search', query: { machinetype: _vm.entry(card).machinetype } }}},[_vm._v(_vm._s(_vm.entry(card).machinetype))]),_vm._v(" - "),_c('router-link',{attrs:{"to":{ path: '/search', query: { genretype: _vm.entry(card).genretype } }}},[_vm._v(_vm._s(_vm.entry(card).genre))])],1)],1)],1)],1)],1)],1)}),1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VContainer,{staticClass:"max-width"},[_c(VPagination,{staticClass:"my-4",attrs:{"length":_vm.numberOfPages,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }