import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"scroll-y pa-3",attrs:{"justify-start":"","fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"center"}},[_c(VSheet,{attrs:{"dark":"","width":"100%"}},[_c(VSlideGroup,{attrs:{"show-arrows":""}},_vm._l((_vm.getLetters),function(n){return _c(VSlideItem,{key:n},[_c(VBtn,{attrs:{"selected":"","plain":"","small":"","text":"","tile":""},on:{"click":function($event){return _vm.byLetter(n)}}},[_vm._v(_vm._s(n)+" ")])],1)}),1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VToolbar,{attrs:{"color":"grey","flat":"","dense":""}},[(!_vm.isLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.searchNumberOfResults)+" results ("+_vm._s(_vm.searchTimeOf)+"ms)")]):_c('span',[_vm._v("searching: "+_vm._s(this.$route.params.query))]),_c(VSpacer),_c(VSelect,{attrs:{"items":_vm.machineTypes,"dense":"","clearable":"","hide-details":"","single-line":"","dark":"","label":"Machinetype"},on:{"change":_vm.reloadPage},model:{value:(_vm.selectedMachine),callback:function ($$v) {_vm.selectedMachine=$$v},expression:"selectedMachine"}}),_c(VProgressLinear,{attrs:{"active":_vm.isLoading,"indeterminate":_vm.isLoading,"absolute":"","bottom":""}})],1),(_vm.errormessage)?_c(VSystemBar,{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()],1)],1),_c('SearchResultGrid',{attrs:{"imagetype":_vm.imagetype,"cards":_vm.cards,"allResults":_vm.allResults,"getPageSize":this.getPageSize,"pageindex":_vm.pageindex},on:{"loadMore":_vm.loadMore}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }