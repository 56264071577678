import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c(VContainer,{staticClass:"scroll-y pa-0",attrs:{"justify-start":"","fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"py-0 ma-0",attrs:{"cols":"12","justify":"center"}},[_c(VToolbar,{staticClass:"pa-0",attrs:{"dense":"","dark":"","color":"grey"}},[_c(VSpacer),_c(VIcon,[_vm._v(_vm._s(_vm.getMagazineTypeIcon(_vm.mag.type)))]),_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.mag.name)+" - "+_vm._s(_vm.mag.publisher)+" ("+_vm._s(_vm.mag.country)+"), "+_vm._s(_vm.mag.language)+" ")]),_c(VSpacer)],1)],1)],1),_c(VRow,{attrs:{"align":'start',"justify":'start'}},_vm._l((_vm.mag.issues),function(card,index){return _c(VCol,{key:index,staticClass:"pa-1",attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,{staticClass:"pa-2",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Issue: "+_vm._s(card.number))]),_c(VCardSubtitle,[_vm._v("Year: "+_vm._s(card.date_year)+", Month: "+_vm._s(card.date_month)+" "),(card.special)?_c('div',[_vm._v("Special: "+_vm._s(card.special))]):_vm._e()]),_c(VCardActions,[_c(VBtn,{staticClass:"ml-2 mt-5",attrs:{"outlined":"","rounded":"","small":"","to":`/magazines/${_vm.mag.name}/issues/${card.id}`}},[_vm._v(" Info ")])],1)],1),_c(VImg,{attrs:{"lazy-src":_vm.getDefaultImageSrc,"aspect-ratio":9 / 16,"max-height":"300","max-width":"200","src":_vm.getScreenUrl(card.cover_image)}})],1)])],1)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }