import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":"","tile":"","height":"100%","width":"100%"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"center"}},[_c(VSheet,{attrs:{"dark":"","width":"100%"}},[_c(VSlideGroup,{attrs:{"show-arrows":""}},_vm._l((_vm.getLetters),function(n){return _c(VSlideItem,{key:n},[_c(VBtn,{attrs:{"plain":"","small":"","text":"","tile":""},on:{"click":function($event){return _vm.byLetter(n)}}},[_vm._v(_vm._s(n)+" ")])],1)}),1)],1)],1)],1),_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"xl":8,"lg":8,"md":8,"sm":8,"xs":12}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('div',[_c('SearchInput',{model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"elevation":"0","small":""},on:{"action":_vm.search}},[_vm._v("ZXDB search")]),_vm._v("  "),_c(VBtn,{attrs:{"elevation":"0","small":""},on:{"click":_vm.random}},[_vm._v("I'm Feeling Lucky")])],1),_c('p'),_c('p',{staticClass:"text-center .caption .font-weight-thin"},[_c('router-link',{attrs:{"to":{ path: '/whatsnew'}}},[_vm._v("ZXDB update 02.08.2024")])],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }