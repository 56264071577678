import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c(VContainer,{staticClass:"scroll-y pa-0",attrs:{"justify-start":"","fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"py-3 ma-0",attrs:{"cols":"12","justify":"center"}},[_c(VCard,{staticClass:"pa-2",attrs:{"dark":"","tile":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"headline"},[_c(VIcon,[_vm._v(_vm._s(_vm.getMagazineTypeIcon(_vm.issue.type)))]),_vm._v(" "+_vm._s(_vm.issue.name))],1),_c(VCardSubtitle,[_vm._v("Year: "+_vm._s(_vm.issue.issue.date_year)+", Month: "+_vm._s(_vm.issue.issue.date_month))]),_c(VCardText,[_vm._v("Publisher: "+_vm._s(_vm.issue.publisher)),_c('br'),_vm._v(" Country: "+_vm._s(_vm.issue.country)),_c('br'),_vm._v("Language: "+_vm._s(_vm.issue.language)+" ")])],1),_c(VImg,{attrs:{"lazy-src":_vm.getDefaultImageSrc,"aspect-ratio":9 / 16,"max-height":"300","max-width":"200","src":_vm.getScreenUrl(_vm.issue.issue.cover_image)}})],1)])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Title ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Type ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Page ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Topic ")])])]),_c('tbody',_vm._l((_vm.getTitlesReferenced),function(item,i){return _c('tr',{key:i},[_c('td',[_c('router-link',{attrs:{"to":{ path: '/details/' + item.entry_id }}},[_vm._v(_vm._s(item.entry_title))])],1),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.page))]),_c('td',[_vm._v(_vm._s(item.featurename))])])}),0)]},proxy:true}],null,false,1289176115)})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }