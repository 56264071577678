import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c(VContainer,{staticClass:"pa-0 fill-height",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"py-0 ma-0",attrs:{"cols":"12","justify":"center"}},[_c(VToolbar,{staticClass:"pa-0",attrs:{"dense":"","dark":"","color":"grey"}},[_c(VSpacer),_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(" magazines ")]),_c(VSpacer)],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Publisher ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Language ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Type ")])])]),_c('tbody',_vm._l((_vm.filteredItems(1, 2)),function(item){return _c('tr',{key:item.name},[_c('td',[_c('router-link',{attrs:{"to":{ path: '/magazines/' + item.name }}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_vm._v(_vm._s(item.publisher))]),_c('td',[_vm._v(_vm._s(item.language))]),_c('td',[_c(VIcon,[_vm._v(_vm._s(_vm.getMagazineTypeIcon(item.type)))])],1)])}),0)]},proxy:true}],null,false,4192149495)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Publisher ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Language ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Type ")])])]),_c('tbody',_vm._l((_vm.filteredItems(2, 2)),function(item){return _c('tr',{key:item.name},[_c('td',[_c('router-link',{attrs:{"to":{ path: '/magazines/' + item.name }}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_vm._v(_vm._s(item.publisher))]),_c('td',[_vm._v(_vm._s(item.language))]),_c('td',[_c(VIcon,[_vm._v(_vm._s(_vm.getMagazineTypeIcon(item.type)))])],1)])}),0)]},proxy:true}],null,false,2997872436)})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }